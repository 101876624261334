.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: red; */
  margin-top: 10px;
  width: 99vw;
}

.double-field {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-evenly;
}

.head {
  font-weight: bold;
  font-size: 24px;
  color: gray;
}

img {
  width: 150px;
  height: 150px;
  margin-left: 10px;
}
